/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import classnames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {IProductPrice} from '../../types/app-types';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';
import {unitsTranslations} from './unitsTranslations';
import {
  formattedPrimaryPrice,
  formattedSecondaryPrice,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';

import s from './ProductPrice.scss';
import {PriceBreakdown} from '@wix/wixstores-client-common-components/dist/es/src/PriceBreakdown/PriceBreakdown';

export interface IProductPriceProps extends IProvidedTranslationProps, IProductPrice, IProvidedGlobalProps {}

export enum DataHook {
  Root = 'product-price',
  FormattedPrice = 'formatted-primary-price',
  FormattedPriceRange = 'price-range-from',
  ProductBasePrice = 'product-base-price',
  OldProductBasePrice = 'old-product-base-price',
  FormattedSecondaryPrice = 'formatted-secondary-price',
  FormattedSecondaryPriceActualValue = 'formatted-secondary-price-actual',
}

class ProductPriceComp extends React.Component<IProductPriceProps> {
  private renderPrimary({shouldUseCommonDiscountPricingMethods}: {shouldUseCommonDiscountPricingMethods: boolean}) {
    const {formattedComparePrice, formattedPrice, comparePrice, t} = this.props;
    const {hasDiscount} = this.props.globals;
    const priceAfterDiscount = this.props.globals.product.itemDiscount?.priceAfterDiscount;

    const getPrimaryPrice = (): string => {
      if (hasDiscount && priceAfterDiscount) {
        return priceAfterDiscount;
      } else if (hasDiscount && Boolean(formattedComparePrice)) {
        return formattedComparePrice;
      }
      return formattedPrice;
    };

    return (
      <>
        <span data-hook={DataHook.FormattedPrice} data-wix-price={formattedPrimaryPrice(this.props.globals.product)}>
          {shouldUseCommonDiscountPricingMethods
            ? formattedPrimaryPrice(this.props.globals.product)
            : getPrimaryPrice()}
        </span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(comparePrice ? 'sr.PRODUCT_PRICE_AFTER_DISCOUNT' : 'sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT')}
        </span>
      </>
    );
  }

  private renderSecondary({shouldUseCommonDiscountPricingMethods}: {shouldUseCommonDiscountPricingMethods: boolean}) {
    const {formattedPrice, t} = this.props;

    return (
      <>
        <span data-hook={DataHook.FormattedSecondaryPrice} className={classnames(s.secondary, s.crossed)}>
          <span data-hook={DataHook.FormattedSecondaryPriceActualValue} data-wix-original-price={formattedPrice}>
            {shouldUseCommonDiscountPricingMethods
              ? formattedSecondaryPrice(this.props.globals.product)
              : formattedPrice}
          </span>
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('sr.PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </>
    );
  }

  private renderPrice() {
    const {hasDiscount} = this.props.globals;
    const {shouldUseCommonDiscountPricingMethods} = this.props.globals.experiments;

    return (
      <>
        {hasDiscount && this.renderSecondary({shouldUseCommonDiscountPricingMethods})}
        {this.renderPrimary({shouldUseCommonDiscountPricingMethods})}
      </>
    );
  }

  private renderPriceRange(formattedFromPrice: string) {
    const {t} = this.props;
    return (
      <>
        <span className="sr-only" data-hook="sr-formatted-price-range">
          {t('sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT')}
        </span>
        <span data-hook={DataHook.FormattedPriceRange}>
          {t('productWidget.price.from.label', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }

  private renderBasePrice() {
    const {
      formattedPricePerUnit,
      pricePerUnitData: {baseQuantity, baseMeasurementUnit},
    } = this.props;

    const noun = baseQuantity === 1 ? 'singular' : 'plural';
    const unitTranslation = this.props.t(unitsTranslations[baseMeasurementUnit].abbr);
    const screenReaderText = this.props.t('productWidget.sr.units.basePrice.label', {
      basePrice: formattedPricePerUnit,
      units: `${baseQuantity} ${this.props.t(unitsTranslations[baseMeasurementUnit][noun])}`,
    });

    return (
      <BasePrice
        data-hook={DataHook.ProductBasePrice}
        className={s.basePrice}
        formattedPricePerUnit={formattedPricePerUnit}
        baseQuantity={baseQuantity}
        unitTranslation={unitTranslation}
        screenReaderText={screenReaderText}
        withRTLSupport
      />
    );
  }

  private readonly sendClickShippingInfoLinkSfEvent = () => {
    const {clickShippingInfoLinkSf} = this.props.globals;
    clickShippingInfoLinkSf();
  };

  public render(): React.ReactNode {
    const {price, priceRange} = this.props;
    const {isDisabled, product} = this.props.globals;
    const shouldRenderBasePrice = !!this.props.formattedPricePerUnit;
    const {shouldRenderTaxDisclaimer, taxDisclaimer, shippingDisclaimer} = this.props.globals.priceBreakdown;

    if (!price && isDisabled) {
      return null;
    }

    return (
      <>
        <div data-hook={DataHook.Root} className={s.productPrice}>
          {priceRange && priceRange.fromPriceFormatted
            ? this.renderPriceRange(priceRange.fromPriceFormatted)
            : this.renderPrice()}
        </div>
        {shouldRenderBasePrice && this.renderBasePrice()}
        {(shouldRenderTaxDisclaimer || shippingDisclaimer?.show) && (
          <PriceBreakdown
            shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
            shippingDisclaimer={shippingDisclaimer}
            taxDisclaimerLabel={taxDisclaimer}
            isDigitalProduct={product.productType === 'digital'}
            isRTL={this.props.globals.isRTL}
            whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
            className={s.priceBreakdown}
          />
        )}
      </>
    );
  }
}

export const ProductPrice = withGlobalProps(withTranslations('globals.texts')(ProductPriceComp));
